v<!--
 * @Author: wangwei
 * @Date: 2020-12-28 16:06:28
 * @LastEditTime: 2023-01-17 20:11:04
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @Description: In User Settings Edit
 * @FilePath: /java-pc/src/views/member/order/List.vue
-->
<template>
  <div>
    <div class="sld_order_list">
      <MemberTitle :memberTitle="L['当月账单']"></MemberTitle>
      <div class="container">
        <h3>{{ L["当月账单"] }}</h3>
        <div class="sld_order_nav">
          <div class="headerSearch">
            <el-form
              ref="ruleFormRef"
              :model="param"
              :inline="true"
              class="demo-form-inline"
            >
              <el-form-item label="订单编号" prop="baseCode">
                <el-input v-model="param.baseCode" style="width: 260px" />
              </el-form-item>
              <el-form-item
                label="订单状态"
                prop="accountPeriodState"
                style="margin-left: 80px"
                v-if="stateData.data.length > 0"
              >
                <el-select
                  v-model="param.accountPeriodState"
                  placeholder=""
                  style="width: 250px"
                >
                  <el-option
                    v-for="item in stateData.data"
                    :key="item.state"
                    :label="item.name"
                    :value="item.state"
                  />
                </el-select>
              </el-form-item>
              <div style="width: 100%"></div>

              <el-form-item label="下单时间" prop="orderStartDate">
                <el-date-picker
                  v-model="param.orderStartDate"
                  type="date"
                  placeholder="开始"
                  style="width: 100%"
                  format="YYYY-MM-DD"
                  value-format="YYYY-MM-DD"
                />
              </el-form-item>
              <span>-</span>
              <el-form-item prop="orderEndDate">
                <el-date-picker
                  v-model="param.orderEndDate"
                  placeholder="结束时间"
                  style="width: 100%"
                  prop="endDate"
                  format="YYYY-MM-DD"
                  value-format="YYYY-MM-DD"
                />
              </el-form-item>
              <div class="searchBtn">
                <div class="button" @click="onSubmit">搜索</div>
                <el-button @click="resetForm(ruleFormRef)">重置</el-button>
              </div>
            </el-form>
          </div>
          <div></div>
        </div>
        <div class="sld_List">
          <div class="tabsBox">
            <div
              :class="borderFlag == 'fgs' ? 'btnActive' : 'tabs'"
              @click="showBorderFlag('fgs')"
              v-if="isShowTab"
            >
              <p class="MonthTitle">{{ Month }}月账单</p>
              <p>(账期时间: {{ Month3 }}.26-{{ Month2 }}.25)</p>
            </div>
            <div
              :class="borderFlag == 'sb' ? 'btnActive' : 'tabs'"
              @click="showBorderFlag('sb')"
            >
              <p class="MonthTitle" style="margin-top: 5px">未入账</p>
            </div>
            <div class="blank"></div>
          </div>
          

          <div
            style="margin-bottom: 20px; font-size: 17px"
            v-if="
              tableData.accountPeriodTopResp.money !== null &&
              tableData.accountPeriodTopResp.money !== 'null' &&
              tableData.accountPeriodTopResp.money
            "
          >
            {{ tableData.accountPeriodTopResp.year }}年{{
              tableData.accountPeriodTopResp.month
            }}月账单剩余末付：￥{{ tableData.accountPeriodTopResp.money }}
          </div>
          <div class="AfterSaleDiv" v-if="AfterSalePageData.total != 0">
            <div
              v-for="items in AfterSaleList.data"
              :key="items"
              class="AfterSaleList"
            >
              <div class="AfterSaleList_colum">
                <div>
                  <span
                    style="
                      color: #5f5f60;
                      font-weight: bold;
                      margin-right: 10px;
                    "
                    >售后单号:</span
                  ><span>{{ items.afterOrderCode }}</span>
                </div>
                <div>
                  <span
                    style="
                      color: #5f5f60;
                      font-weight: bold;
                      margin-right: 10px;
                    "
                    >订单编号:</span
                  ><span>{{ items.memberOrderCode }}</span>
                </div>
                <div>
                  <span
                    style="
                      color: #5f5f60;
                      font-weight: bold;
                      margin-right: 10px;
                    "
                    >付款金额:</span
                  ><span>-{{ items.returnMoney }}</span>
                </div>
              </div>
            </div>
            <div class="AfterSaleDiv_topDiv">
              <div
                style="display: flex; align-items: flex-end; margin-right: 10px"
              >
                共<span style="font-size: 14px; padding: 0 5px">{{
                  AfterSalePageData.total
                }}</span
                >条
              </div>
              <div>
                <p v-if="AfterSaleList.data1.length >1&& !isDown" @click="unfold()">
                  <span style="color: #0e3177; margin-right: 3px">展开</span
                  ><img :src="downImg" alt=""  />
                </p>
                <p v-if="AfterSaleList.data1.length >1&&isDown" @click="unfold()">
                  <span style="color: #0e3177; margin-right: 3px">收起</span
                  ><img :src="upImg" alt=""  />
                </p>
              </div>
            </div>
            <el-pagination
              v-if="(AfterSaleList.data1.length >=5&&isDown)||(AfterSalePageData.pageNum>1&&AfterSaleList.data1.length ==1)"
              v-model:current-page="AfterSalePageData.pageNum"
              v-model:page-size="AfterSalePageData.pageSize"
              :page-sizes="[5, 10, 15, 20]"
              layout="sizes, prev, pager, next"
              :total="AfterSalePageData.total"
              @size-change="handleSizeChange2"
              @current-change="handleCurrentChange2"
            />
          </div>
          <div v-if="borderFlag == 'fgs'">
            <!--:data="tableData.data"-->
            <el-table
              :data="tableData.dataPage.records"
              border
              max-width="250"
              header-cell-style="background:#FAFAFAFF;"
              v-if="borderFlag == 'fgs'"
            >
              <el-table-column
                prop="baseCode"
                label="订单编号"
                width="180"
                align="center"
              />
              <el-table-column
                prop="sellName"
                label="卖方"
                width="100"
                align="center"
              />

              <el-table-column
                prop="orderAmount"
                label="订单金额"
                max-height="60"
                align="center"
              />
              <el-table-column
                prop="placeOrderTime"
                label="下单时间"
                align="center"
              />
              <el-table-column
                prop="finishTime"
                label="收货时间"
                align="center"
              />
              <el-table-column prop="remark" label="备注" align="center" />
              <el-table-column prop="orderState" label="状态" align="center">
                <template #default="scope">
                  <el-tag
                    v-if="scope.row.type == 1 && scope.row.orderState == 0"
                    @click="handleDelete(scope.$index, scope.row)"
                    class="ml-2"
                    effect="light"
                  >
                    已取消
                  </el-tag>
                  <el-tag
                    v-if="scope.row.type == 1 && scope.row.orderState == 1"
                    @click="handleDelete(scope.$index, scope.row)"
                    type="warning"
                    class="ml-2"
                    effect="light"
                  >
                    待审核
                  </el-tag>

                  <el-tag
                    v-if="scope.row.type == 1 && scope.row.orderState == 10"
                    @click="handleDelete(scope.$index, scope.row)"
                    type="success"
                    class="ml-2"
                    effect="light"
                  >
                    待付款
                  </el-tag>
                  <el-tag
                    v-if="scope.row.type == 1 && scope.row.orderState == 30"
                    @click="handleDelete(scope.$index, scope.row)"
                    type="success"
                    class="ml-2"
                    effect="light"
                  >
                    待收货
                  </el-tag>
                  <el-tag
                    v-if="scope.row.type == 1 && scope.row.orderState == 20"
                    @click="handleDelete(scope.$index, scope.row)"
                    type="info"
                    class="ml-2"
                    effect="plain"
                  >
                    待发货
                  </el-tag>
                  <el-tag
                    v-if="scope.row.type == 1 && scope.row.orderState == 40"
                    @click="handleDelete(scope.$index, scope.row)"
                    type="info"
                    class="ml-2"
                    effect="plain"
                  >
                    已完成
                  </el-tag>
                  <el-tag
                    v-if="scope.row.type == 1 && scope.row.orderState == 50"
                    @click="handleDelete(scope.$index, scope.row)"
                    type="info"
                    class="ml-2"
                    effect="plain"
                  >
                    已关闭
                  </el-tag>

                  <el-tag
                    v-if="scope.row.type == 2 && scope.row.orderState == 1"
                    @click="handleDelete(scope.$index, scope.row)"
                    type="danger"
                    class="ml-2"
                    effect="plain"
                  >
                    退款成功
                  </el-tag>
                  <el-tag
                    v-if="scope.row.type == 2 && scope.row.orderState == 2"
                    @click="handleDelete(scope.$index, scope.row)"
                    type="danger"
                    class="ml-2"
                    effect="plain"
                  >
                    退款失败
                  </el-tag>
                  <el-tag
                    v-if="scope.row.type == 2 && scope.row.orderState == 0"
                    @click="handleDelete(scope.$index, scope.row)"
                    type="warning"
                    class="ml-2"
                    effect="plain"
                  >
                    退款中
                  </el-tag>
                </template>
              </el-table-column>
              <el-table-column prop="address" label="操作" align="center">
                <template #default="scope">
                  <p
                    class="look"
                    type="primary"
                    size="small"
                    @click="toDetail(scope.row)"
                    link
                  >
                    查看详情
                  </p>
                </template>
              </el-table-column>
            </el-table>
            <el-pagination
              style="margin-top: 10px"
              :vi-if="param.total != 0"
              v-model:current-page="param.pageNum"
              v-model:page-size="param.pageSize"
              :page-sizes="[10, 20, 30, 40]"
              :small="small"
              :disabled="disabled"
              :background="background"
              layout="total, sizes, prev, pager, next, jumper"
              :total="param.total"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
            />
            <div class="moneyList">
              <!--<p><span style="color: red; font-size: 14px">合计：</span></p>-->
              <div style="margin-top: 30px; line-height: 1.3">
                <p>
                  <span class="fontStyle">本月应付金额：</span>￥{{
                    tableData.accountPeriodMain.shouldAccount
                  }}
                </p>
                <p>
                  <span class="fontStyle">本月实付金额：</span>￥{{
                    tableData.accountPeriodMain.realAccount
                  }}
                </p>
                <p class="yellow">本期账单未支付的金额自动转入下期账单</p>
              </div>
              {{ tableData.accountPenodMain }}
              <div
                v-if="
                  tableData?.dataPage?.records?.length != 0 ||
                  tableData?.accountPeriodMain?.id
                "
              >
                <div
                  v-show="tableData?.dataPage?.records"
                  class="paymentButton"
                  @click="settlement(tableData.accountPeriodMain.periodCode)"
                >
                  申请结算
                </div>
              </div>
            </div>
          </div>
          <div v-if="borderFlag == 'sb'">
            <el-table
              :data="tableData.dataPage.records"
              border
              max-width="250"
              header-cell-style="background:#FAFAFAFF;"
              v-if="borderFlag == 'sb'"
            >
              <el-table-column
                prop="baseCode"
                label="订单编号"
                width="180"
                align="center"
              />
              <el-table-column
                prop="sellName"
                label="卖方"
                width="100"
                align="center"
              />
              <el-table-column
                prop="orderAmount"
                label="订单金额"
                max-height="60"
                align="center"
              />
              <el-table-column
                prop="placeOrderTime"
                label="下单时间"
                align="center"
              />
              <el-table-column
                prop="finishTime"
                label="收货时间"
                align="center"
              />
              <el-table-column prop="remark" label="备注" align="center" />
              <el-table-column prop="orderState" label="状态" align="center">
                <template #default="scope">
                  <el-tag
                    v-if="scope.row.type == 1 && scope.row.orderState == 0"
                    @click="handleDelete(scope.$index, scope.row)"
                    class="ml-2"
                    effect="light"
                  >
                    已取消
                  </el-tag>
                  <el-tag
                    v-if="scope.row.type == 1 && scope.row.orderState == 1"
                    @click="handleDelete(scope.$index, scope.row)"
                    type="warning"
                    class="ml-2"
                    effect="light"
                  >
                    待审核
                  </el-tag>

                  <el-tag
                    v-if="scope.row.type == 1 && scope.row.orderState == 10"
                    @click="handleDelete(scope.$index, scope.row)"
                    type="success"
                    class="ml-2"
                    effect="light"
                  >
                    待付款
                  </el-tag>
                  <el-tag
                    v-if="scope.row.type == 1 && scope.row.orderState == 30"
                    @click="handleDelete(scope.$index, scope.row)"
                    type="success"
                    class="ml-2"
                    effect="light"
                  >
                    待收货
                  </el-tag>
                  <el-tag
                    v-if="scope.row.type == 1 && scope.row.orderState == 20"
                    @click="handleDelete(scope.$index, scope.row)"
                    type="info"
                    class="ml-2"
                    effect="plain"
                  >
                    待发货
                  </el-tag>
                  <el-tag
                    v-if="scope.row.type == 1 && scope.row.orderState == 40"
                    @click="handleDelete(scope.$index, scope.row)"
                    type="danger"
                    class="ml-2"
                    effect="plain"
                  >
                    已完成
                  </el-tag>
                  <el-tag
                    v-if="scope.row.type == 1 && scope.row.orderState == 50"
                    @click="handleDelete(scope.$index, scope.row)"
                    type="info"
                    class="ml-2"
                    effect="plain"
                  >
                    已关闭
                  </el-tag>
                  <el-tag
                    v-if="scope.row.type == 2 && scope.row.orderState == 1"
                    @click="handleDelete(scope.$index, scope.row)"
                    type="danger"
                    class="ml-2"
                    effect="plain"
                  >
                    退款成功
                  </el-tag>
                  <el-tag
                    v-if="scope.row.type == 2 && scope.row.orderState == 2"
                    @click="handleDelete(scope.$index, scope.row)"
                    type="danger"
                    class="ml-2"
                    effect="plain"
                  >
                    退款失败
                  </el-tag>
                  <el-tag
                    v-if="scope.row.type == 2 && scope.row.orderState == 0"
                    @click="handleDelete(scope.$index, scope.row)"
                    type="warning"
                    class="ml-2"
                    effect="plain"
                  >
                    退款中
                  </el-tag>
                </template>
              </el-table-column>
              <el-table-column prop="address" label="操作" align="center">
                <template #default="scope">
                  <p
                    class="look"
                    type="primary"
                    size="small"
                    @click="toDetail(scope.row)"
                    link
                  >
                    查看详情
                  </p>
                </template>
              </el-table-column>
            </el-table>
            <el-pagination
              style="margin-top: 10px"
              v-model:current-page="param.pageNum"
              v-model:page-size="param.pageSize"
              :page-sizes="[10, 20, 30, 40]"
              :small="small"
              :disabled="disabled"
              :background="background"
              layout="total, sizes, prev, pager, next, jumper"
              :total="param.total"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
            />
            <div class="moneyList">
              <!--<p style="margin-bottom: 20px">
                <span style="font-size: 14px">{{ Month }}月账单剩未末付:</span>
              </p>-->
              <p>
                <span style="color: red; font-size: 14px">合计：</span>￥{{
                  tableData.accountPeriodMain.shouldAccount
                }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--isOpenSettlementModal-->
  </div>
</template>
<script>
import { reactive, getCurrentInstance, ref, onMounted } from "vue";
import {
  ElInput,
  ElMessage,
  ElMessageBox,
  ElTimeline,
  ElTimelineItem,
} from "element-plus";
// import { Search } from '@element-plus/icons-vue'
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { apiUrl } from "../../../utils/config";
import axios from "axios";
import SldCommonEmpty from "../../../components/SldCommonEmpty";
import MemberTitle from "../../../components/MemberTitle";
import download from "downloadjs";
import { platform } from "@/utils/config";
import upImg from "../../../assets/iconImages/up@2x.png";
import downImg from "../../../assets/iconImages/down@2x.png";
export default {
  name: "order-list",
  components: {
    ElInput,
    ElTimeline,
    ElTimelineItem,
    SldCommonEmpty,
    MemberTitle,
  },
  setup() {
    // const balance = require("../../assets/buy/balance.png");
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const memberInfo = reactive({ data: store.state.memberInfo });
    const { proxy } = getCurrentInstance();
    const L = proxy.$getCurLanguage();

    const ruleFormRef = ref();
    const borderFlag = ref("fgs");
    const showFlag = ref(true);
    const isOpenSettlementModal = ref(false);
    const orderSn = ref(""); //订单id
    const isShowTab = ref(true);
    const isDown = ref(false);
    const tableData = reactive({
      dataPage: [],
      accountPeriodTopResp: {},
      accountPeriodMain: [],
      accountPeriodTitles: [],
    });
    const Month = ref("");
    const Month2 = ref("");
    const Month3 = ref("");
    const stateData = reactive({ data: [] });
    const AfterSaleList = reactive({
      data: [
        {
          afterOrderCode: "1111111111111",
          memberOrderCode: "1111111111111",
          returnMoney: "1111111111",
        },
      ],
      data1: [
        {
          afterOrderCode: "1111111111111",
          memberOrderCode: "1111111111111",
          returnMoney: "1111111111",
        },
        {
          afterOrderCode: "1111111111111",
          memberOrderCode: "1111111111111",
          returnMoney: "1111111111",
        },
        {
          afterOrderCode: "1111111111111",
          memberOrderCode: "1111111111111",
          returnMoney: "1111111111",
        },
        {
          afterOrderCode: "1111111111111",
          memberOrderCode: "1111111111111",
          returnMoney: "1111111111",
        },
      ],
    });
    const param = reactive({
      //memberId: store.state.memberInfo.memberId,
      baseCode: "",
      accountPeriodState: "",
      orderStartDate: "",
      orderEndDate: "",
      pageNum: 1,
      pageSize: 10,
      total: 0,
      type: 1,
    });

    const AfterSalePageData = reactive({
      pageNum: 1,
      pageSize: 5,
      total: 0,
    });
    const pageData = reactive({
      pageNum: 1,
      pageSize: 10,
      total: 0,
    });
    const onSubmit = () => {
      param.pageNum = 10;
      param.pageNum = 1;
      getOrderList();
      console.log("submit!");
    };
    const toDetail = (item) => {
      if (item.type == 2) {
        router.push({
          path: "/member/order/refund/detail",
          query: {
            orderId: item.baseCode,
            orderState: item.orderState,
          },
        });
      } else {
        router.push({
          path: "/member/order/detail",
          query: {
            orderSn: item.baseCode,
          },
        });
      }
    };
    const resetForm = (formEl) => {
      if (!formEl) return;
      formEl.resetFields();
      param.pageNum = 1;
      getOrderList();

      //param.resetFields()
      //param.planCode= "",
      // param. source= "",
      // param. prayName= "",
      // param. startDate= "",
      // param. endDate= "",
      //  param.resource= "",
      //  param.state= "",
      //getOrderList()
    };
    const isSettlement = (code) => {};
    const getOrderList = () => {
      tableData.dataPage = [];
      tableData.accountPeriodTopResp = [];
      tableData.accountPeriodMain = [];
      tableData.accountPeriodTitles = [];
      console.log(borderFlag.value === "fgs");
      if (borderFlag.value === "fgs") {
        param.type = 1;
      } else if (borderFlag.value === "sb") {
        param.type = 2;
      }
      console.log(param);
      proxy
        .$post("api/shopp/accountPeriod/queryAccountPeriod", param, "json")
        .then((res) => {
          //原接口：v3/business/front/cart/cartList
          if (res.code == 200) {
            tableData.dataPage = res.data.dataPage;
            tableData.accountPeriodTopResp = res.data.accountPeriodTopResp;
            tableData.accountPeriodMain = res.data.accountPeriodMain;
            tableData.accountPeriodTitles = res.data.accountPeriodTitles;
            if (
              res.data.dataPage.records &&
              res.data.dataPage.records.length !== 0
            ) {
              console.log(77777);
              param.pageSize = res.data.dataPage.pageSize;
              param.pageNum = res.data.dataPage.pageNum;
              param.total = res.data.dataPage.total;
              console.log(param.total);
            } else if (
              param.type == 1 &&
              res.data.dataPage.records.length == 0 &&
              res.data.accountPeriodMain.id
            ) {
              //console.log(param.pageSize,878787);
              // param.pageSize = res.data.dataPage.pageSize;
              //param.pageNum = res.data.dataPage.pageNum;
              //param.total = res.data.dataPage.total;
              //console.log(param.total);
              //console.log( tableData.accountPeriodMain.id,6768787);
              //          const Month = ref("");
              //const Month2 = ref("");
              //const Month3 = ref("");
            } //console.log(param.type==1&&res.data.dataPage.records&&res.data.dataPage.records.length==0,80909770);
            else if (
              param.type == 1 &&
              res.data.dataPage.records &&
              res.data.dataPage.records.length == 0 &&
              param.baseCode == "" &&
              param.accountPeriodState == "" &&
              param.orderStartDate == "" &&
              param.orderEndDate == ""
            ) {
              param.type = 2;
              borderFlag.value = "sb";
              isShowTab.value = false;
              getOrderList();
            }
            getAfterSaleList( param.type)
            //cartLen.value = res.data.count ? res.data.count : 0
          } else {
            console.log(88888);
            ElMessage.error(res.msg);
          }
        });
    };
    const getMonth = () => {
      var today = new Date();
      var currentDay = today.getDate();

      //如果当前日期没有超过26号，则显示上一个月
      if (currentDay < 26) {
        var lastMonth = new Date();
        lastMonth.setMonth(lastMonth.getMonth() - 1);
        console.log(lastMonth.getMonth() + 1);
        Month.value = lastMonth.getMonth() + 1;
      } else {
        Month.value = today.getMonth() + 1;
      }

      if (currentDay <= 25) {
        var lastMonth = new Date();
        lastMonth.setMonth(lastMonth.getMonth() - 1);
        Month2.value = lastMonth.getMonth() + 1;
      } else {
        Month2.value = today.getMonth() + 1;
      }

      if (currentDay < 26) {
        // 如果今天是26号或者之前，则显示上上个月

        today.setMonth(today.getMonth() - 2);
      } else {
        // 如果今天已经超过了26号，则显示上个月
        today.setMonth(today.getMonth() - 1);
      }

      Month3.value = today.getMonth() + 1; // 月份从0开始，所以要加1
    };
    const handleSizeChange = (val) => {
      param.pageSize = val;
      getOrderList();
      console.log(`${val} items per page`);
    };
    const handleCurrentChange = (val) => {
      param.pageNum = val;
      getOrderList();
      console.log(`current page: ${val}`);
    };
    const handleSizeChange2 = (val) => {
     AfterSalePageData.pageSize = val;
      getAfterSaleList(borderFlag.value === "fgs"? 1:2);
      console.log(`${val} items per page`);
     
    };
    const handleCurrentChange2 = (val) => {
      AfterSalePageData.pageNum = val;
       isDown.value=false
     getAfterSaleList(borderFlag.value === "fgs"? 1:2);
      console.log(`current page: ${val}`);
    };
    const showBorderFlag = (type) => {
      console.log(1111);
      borderFlag.value = type;
      param.pageSize = 10;
      param.pageNum = 1;
      isDown.value=false
       getAfterSaleList(borderFlag.value === "fgs"? 1:2);
      getOrderList();

      
    };
    const getOrderStateData = () => {
      proxy.$get("api/shopp/accountPeriod/queryOrderState").then((res) => {
        //原接口：v3/business/front/cart/cartList
        if (res.code == 200) {
          console.log(res.data, 22222222);
          stateData.data = res.data;
          //cartLen.value = res.data.count ? res.data.count : 0
        }
      });
    };
    const settlement = (periodCode) => {
      let arr = {
        periodCode: periodCode,
      };
      console.log(periodCode);
      if (periodCode) {
        ElMessageBox.confirm("是否申请结算?", L["提示"], {
          confirmButtonText: L["确定"],
          cancelButtonText: L["取消"],
          type: "warning",
        })
          .then(() => {
            proxy
              .$post("api/shopp/accountPeriod/apply?periodCode=" + periodCode)
              .then((res) => {
                //原接口：v3/business/front/cart/cartList
                if (res.code == 200) {
                  ElMessage({
                    message: res.msg,
                    type: "success",
                  });
                  isShowTab.value = false;
                  borderFlag.value = "sb";
                  param.type = 2;
                  isShowTab.value = false;
                  getOrderList();
                  //cartLen.value = res.data.count ? res.data.count : 0
                } else {
                  ElMessage.error(res.msg);
                }
              });
          })
          .catch(() => {});
      } else {
        ElMessage.error("没有要结算的账单");
      }
    };
    const getAfterSaleList = (type) => {
      
      proxy
        .$get("api/shopp/accountPeriod/queryAccountPeriodReturnOrder", {
          ...AfterSalePageData,
          type,
        })
        .then((res) => {
          if (res.code == 200) {
            AfterSaleList.data1=res.data.records
            AfterSaleList.data=AfterSaleList.data1.slice(0,1)
            AfterSalePageData.pageNum = res.data.pageNum;
            AfterSalePageData.pageSize = res.data.pageSize;
            AfterSalePageData.total = res.data.total;
            AfterSalePageData.pageNum = res.data.pageNum;
            //cartLen.value = res.data.count ? res.data.count : 0
          } else {
            ElMessage.error(res.msg);
          }
        });
    };
    const unfold = () => {
      isDown.value = !isDown.value;
      if (isDown.value) {
        AfterSaleList.data = AfterSaleList.data1;
      } else {
        console.log(AfterSaleList.data1);
        AfterSaleList.data = AfterSaleList.data1.slice(0, 1);
      }
      
    };
    onMounted(() => {
      //  if (route.query.orderState) {
      //    current_state.value = route.query.orderState;
      //  } else {
      //    current_state.value = "";
      //  }
      //  if (route.query.evaluateState) {
      //    evaluate_state.value = route.query.evaluateState;
      //  } else {
      //    evaluate_state.value = "";
      //  }
      //  getTime();
      //getAfterSaleList();
      getOrderList();
      getMonth();
      getOrderStateData();
      //  getCancelList();
      //  getAddressList();
    });

    //router.beforeEach((to, from, next) => {
    //  //  if (to.query.orderState) {
    //  //    current_state.value = to.query.orderState;
    //  //  } else {
    //  //    current_state.value = "";
    //  //  }
    //  //  if (to.query.evaluateState) {
    //  //    evaluate_state.value = to.query.evaluateState;
    //  //  } else {
    //  //    evaluate_state.value = "";
    //  //  }
    //  if (to.path == "/member/procurement/procurementPlanList") {
    //  }
    //  next();
    //});

    return {
      L,

      getOrderList,
      onSubmit,
      Month,
      param,
      tableData,
      toDetail,
      resetForm,
      ruleFormRef,
      pageData,
      handleSizeChange,
      handleCurrentChange,
      borderFlag,
      showFlag,
      showBorderFlag,
      getOrderStateData,
      stateData,
      settlement,
      isSettlement,
      isOpenSettlementModal,
      isShowTab,
      Month2,
      Month3,
      getAfterSaleList,
      AfterSaleList,
      AfterSalePageData,
      isDown,
      upImg,
      downImg,
      unfold,
      handleSizeChange2,
      handleCurrentChange2,
    };
  },
};
</script>
<style lang="scss">
@import "../../../style/orderList.scss";
.demo-tabs > .el-tabs__content {
  padding: 32px;
  color: #6b778c;
  font-size: 32px;
  font-weight: 600;
}
.el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active {
  color: #0e3177;
  background-color: rgb(237, 235, 235);
  border-right-color: var(--el-border-color-base);
  border-left-color: var(--el-border-color-base);
  border-top: 4px solid #0e3177;
}
.demo-tabs .custom-tabs-label {
  display: flex;
  height: 40px;
}
.sld_order_list .container {
  width: 1018px;
}
.el-pager li.active {
  color: $colorMain;
  cursor: default;
  font-size: 16px;
}
.look {
  color: rgb(74, 122, 211);
  cursor: pointer;
}
.el-pager li:hover {
  color: $colorMain;
}

.el-select-dropdown__item.selected {
  color: $colorMain;
}

.sld_order_list {
  color: #000;
  .sld_List {
    color: #000;
  }
  ::v-deep .el-dialog__header {
    background-color: $colorMain;
    padding: 11px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    ::v-deep .el-dialog__title {
      color: #fff;
      font-size: 14px;
    }
    ::v-deep .el-dialog__headerbtn {
      top: auto;
    }
    ::v-deep .el-dialog__headerbtn .el-dialog__close {
      color: #fff !important;
      font-size: 18px;
    }
  }
  ::v-deep .el-dialog {
    overflow: hidden;
    border-radius: 4px;
  }
  ::v-deep .el-dialog__footer {
    display: flex;
    // justify-content: center;
    align-items: center;
  }
  .aft_btn:hover {
    background-color: rgba($color: #111785, $alpha: 0.2);
    color: #200ea7;
  }
  .aft_btn:focus {
    background-color: #fff;
    color: #333333;
    border-color: #dcdfe6;
  }
  .dia_btn {
    .el-button {
      width: 134px;
      height: 36px;
    }
  }
  .downLoad_contract {
    width: 150px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border: 1px solid #e8e8f1;
    border-radius: 5px;
    cursor: pointer;
  }
  .downLoad_contract:hover {
    border: 1px solid $colorMain;
  }
}
.container {
  .bootom-footer {
    text-align: right;
  }
  .bootom-footer button:first-child {
    margin-right: 10px;
  }
  // .el-input {
  //   width: 300px;
  // }
  .unit {
    font-size: 18px;
    margin-left: 10px;
  }
}
.demo-form-inline .el-input {
  --el-input-width: 220px;
}
.el-table thead {
  color: #5f5f60;
}
.el-table {
  color: #2b2b2c;
}
.sld_order_button {
  margin-bottom: 10px;
}
.el-radio {
  margin-right: 0 !important;
}
.button {
  background: #0e3177;
  cursor: pointer;
  width: 70px;
  height: 40px;
  border-radius: 4px;
  text-align: center;
  color: #fff;
  /*margin-left: 10px;*/
  line-height: 40px;
  font-size: 14px;
  margin-right: 10px;
}
.headerSearch {
  position: relative;
}
.searchBtn {
  cursor: pointer;
  display: flex;
  width: 200px;
  position: absolute;
  top: 0%;
  left: 85%;
}
.el-pagination.is-background .el-pager li:not(.disabled).active {
  /*background: #03499e;*/
}
.tabsBox {
  width: 100%;
  height: aoto;
  display: flex;
  font-size: 15px;
  align-items: center;
  justify-content: flex-start;
  margin-top: 20px;
  margin-bottom: 20px;
  .tabs {
    text-align: center;
    width: 30%;
    cursor: pointer;
    padding: 10px 0px;
    /*line-height: 1.5;*/
    height: 56px;
    border-bottom: 1px solid #ccc;
    .MonthTitle {
      font-size: 18px;
    }
  }
  .btnActive {
    border-top: 4px #0e3177 solid;
    background-color: #ffffff;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    cursor: pointer;
    text-align: center;
    color: #0e3177;
    width: 30%;
    /*line-height: 1.5;*/
    height: 56px;
    padding: 10px 0px;
    background: rgb(250, 250, 250);
    .MonthTitle {
      font-size: 18px;
      font-weight: bold;
    }
    /*line-height: 1.5;*/
  }
}
.moneyList {
  text-align: right;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  .fontStyle {
    font-size: 14px;
    color: #909090ff;
    font-family: MicrosoftYaHei;
  }
  .yellow {
    font-size: 12px;
    color: #e18f4cff;
    margin-top: 5px;
    background-color: #fff2e3ff;
    padding: 2px;
  }
  .paymentButton {
    background: #0e3177ff;
    width: 102px;
    height: 31px;
    border-radius: 4px;
    line-height: 31px;
    text-align: center;
    color: #ffff;
    font-size: 14px;
    margin-top: 16px;
    cursor: pointer;
  }
}
.blank {
  width: 100%;
  border-bottom: 1px solid #ccc;
  padding: 28px 0px;
}
.el-button--primary {
  --el-button-font-color: #ffffff;
  --el-button-background-color: #0e3177ff !important;
}
.el-button--primary {
  --el-button-background-color: #0e3177;
  --el-button-border-color: #0e3177;
  --el-button-hover-color: #0e3177;
  --el-button-background-color: #0e3177;
  --el-button-border-color: #0e3177;
  --el-button-hover-color: #0e3177;
}
.sld_List {
  /*position: relative;*/
  width: 100%;
  .AfterSaleDiv {
    /*position: absolute;*/
    width: 100%;
    border: 1px solid #ebeef5;
    padding: 23px;
    margin-bottom: 15px;
    /*display: flex;*/
    position: relative;
    .AfterSaleDiv_topDiv {
      top: 38px;
      right: 25px;
      position: absolute;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
    }
    .AfterSaleList {
      /*justify-content: space-around;*/
      font-size: 14px;
      width: 100%;
      /*width: 90%;*/
      .AfterSaleList_colum {
        display: flex;
        div {
          margin: 0 60px 0px 40px;

          line-height: 50px;
        }
      }
    }
  }
}
</style>